import React, {useState} from "react";
import { useTriggerTransition } from "gatsby-plugin-transition-link/hooks/useTriggerTransition"
import { swipe } from './swipe'
import {GlobalDispatchContext, GlobalStateContext} from './context/GlobalContextProvider'


export const congrats = [
"[x player]'s Taken The Lead",
"[x player] They Will Never Catch You!",
"You're A Pro [x player]",
"[x player] You're Under Par. In a Good Way!",
"Tweet tweet. Birdie For [x player] ",
"[x player]'s Soaring Like an Eagle",
"[x player] is On Fire!",
"[x player] You're Born to Win",
"[x player] You've Won The Last [number] Holes",
"[x player] They Should Have Named You Tiger",
"You're Either Lucky or Incredible [x player]",
"Congrats [x player] You've won!",
"[x player] You Must Have Been Practicing!",
"Skill or Luck...That's Incredible [x player]",
"[x player] They Will Make a Tiki Head of You Some Day",
];


export const birdies = [

  "Tweet tweet. Birdie For [x player] ",
  "[x player] They Should Have Named You Tiger",
  "[x player] You Must Have Been Practicing!",
  ];

export const taunts = [
"In Your Face [x player]. [y player] Is In The Lead!",
"[x player] You've Got More Bogey's Than a Nosepicker",
"You're Streak Is Over [x player]",
"There's Bad and Then Theres [x player]",
"Shots Fired....Shot's Missed [x player]",
"Swing and a Miss, and a Miss [x player]",
"[x player] You, Need to Step up Your Game",
"[x player] You're doing the Bogey Boogie",
"[x player] You're getting as Bad as [y player]",
"[x player] Making the Others Look Good, Right?",
"[x player] You Do Know You're Meant to Keep your Score Low?",
"[x player] Is Great. The Rest of You....Not so Much!",
"[x player] MJ Wrote a Song About You. Rhymes With Sad...",
"[x player] Are You Letting [yplayer] Win?",
]

export const bogies = [
  "[x player] MJ Wrote a Song About You. Rhymes With Sad...",
  "[x player] Are You Letting [yplayer] Win?",
  "[x player] You're doing the Bogey Boogie",
  "[x player] You, Need to Step up Your Game",
  "There's Bad and Then Theres [x player]",
];

export const loserOverall = [
  "There's Bad and Then Theres [x player]",
  "[x player] You, Need to Step up Your Game",
  "[x player] You Do Know You're Meant to Keep your Score Low?",
  "[x player] MJ Wrote a Song About You. Rhymes With Sad...",
  "You're Either Lucky or Incredible [x player]",
];


export const leadOverall = [
  "Fantastic Round from [x player]",
  "[x player] is All Conquering. ",
  "[x player] That's a Pro Level Round. Well Done!",
  "[x player] is On Fire!",
  "You're Either Lucky or Incredible [x player]",
  "[x player] Is Great. The Rest of You....Not so Much!",
];

export const holeInOne = [
  "One and done from [x player]",
  "Wow. Hole in One From [x player]",
];

export const specials = [
"Wow. Hole in One From [x player]",
"[x player] Beat you at the Last Hole!",
"Fantastic Round from [x player]",
"Bow Before [x player] Golf Legend.",
"[x player] That's a Pro Level Round. Well Done!",
"[x player] is All Conquering. ",
"[x player] You Won EVERY Hole. Show Off?",
]


export const holes2 = [
  {
    name: 'Hole 1',
    par: 5
  }, {
    name: 'Hole 2',
    par: 4
  }, {
    name: 'Hole 3',
    par: 3
  }, {
    name: 'Hole 4',
    par: 3
  }, {
    name: 'Hole 5',
    par: 6
  }, {
    name: 'Hole 6',
    par: 3
  }, {
    name: 'Hole 7',
    par: 3
  }, {
    name: 'Hole 8',
    par: 3
  }, {
    name: 'Hole 9',
    par: 3
  }, {
    name: 'Hole 10',
    par: 3
  }, {
    name: 'Hole 11',
    par: 3
  }, {
    name: 'Hole 12',
    par: 3
  }, {
    name: 'Hole 13',
    par: 3
  }, {
    name: 'Hole 14',
    par: 3
  }, {
    name: 'Hole 15',
    par: 3
  }, {
    name: 'Hole 16',
    par: 3
  }, {
    name: 'Hole 17',
    par: 3
  }, {
    name: 'Hole 18',
    par: 3
  }
  , {
    name: 'Bonus Hole!',
    par: 3
  }
];
export const holes = [
  {
    name: 'Hole 1',
    par: 3
  }, {
    name: 'Hole 2',
    par: 3
  }, {
    name: 'Hole 3',
    par: 3
  }, {
    name: 'Hole 4',
    par: 3
  }, {
    name: 'Hole 5',
    par: 3
  }, {
    name: 'Hole 6',
    par: 3
  }, {
    name: 'Hole 7',
    par: 3
  }, {
    name: 'Hole 8',
    par: 3
  }, {
    name: 'Hole 9',
    par: 3
  }, {
    name: 'Hole 10',
    par: 3
  }, {
    name: 'Hole 11',
    par: 3
  }, {
    name: 'Hole 12',
    par: 3
  }, {
    name: 'Hole 13',
    par: 3
  }, {
    name: 'Hole 14',
    par: 3
  }, {
    name: 'Hole 15',
    par: 3
  }, {
    name: 'Hole 16',
    par: 3
  }, {
    name: 'Hole 17',
    par: 3
  }, {
    name: 'Hole 18',
    par: 3
  }
  , {
    name: 'Bonus Hole!',
    par: 3
  }
];

export const mk = [
  {
    name: 'Hole 1',
    par: 3
  }, {
    name: 'Hole 2',
    par: 3
  }, {
    name: 'Hole 3',
    par: 3
  }, {
    name: 'Hole 4',
    par: 3
  }, {
    name: 'Hole 5',
    par: 3
  }, {
    name: 'Hole 6',
    par: 3
  }, {
    name: 'Hole 7',
    par: 3
  }, {
    name: 'Hole 8',
    par: 3
  }, {
    name: 'Hole 9',
    par: 3
  }, {
    name: 'Hole 10',
    par: 3
  }, {
    name: 'Hole 11',
    par: 3
  }, {
    name: 'Hole 12',
    par: 3
  }
];


export const coney = [
  {
    name: '1. Cyclone',
    par: 3
  }, {
    name: '2. Ball toss',
    par: 3
  },  {
    name: '3. Plinko ',
    par: 3
  },{
    name: '4. Duck shoot',
    par: 3
  }, {
    name: '5. Drum roll',
    par: 3
  }, {
    name: '6. Wall of death',
    par: 3
  }, {
    name: '7. Helter skelter',
    par: 3
  }, {
    name: '8. Skee Ball',
    par: 3
  }, {
    name: '9. Lion Tamer ',
    par: 3
  }, {
    name: '10. Pinball Wizard ',
    par: 3
  }, {
    name: '11. Shoot the Loop',
    par: 3
  }, {
    name: '12. High striker', 
    par: 3
  }
];

export const clubs = [
{
  label: "Castleford",
  code: "castleford",
  value: "Castleford",
  holes: [{label:"T-Rex Trail", value:"T-Rex Trail", max:18, id:0, hole_config: holes},{label:"Volcano Valley", value:"Volcano Valley", max:18, id:1, hole_config: holes}], 
  freeHole:false,
},{
  label:"Edinburgh",
  code:"edinburgh",
  value:"Edinburgh",
  holes: [{ label: "Volcano Valley", value:"Volcano Valley", max:18, id:0,hole_config: holes}, {label: "Skull Falls", value:"Skull Falls", max:18, id:1, hole_config: holes}], 
  freeHole:true
},{
  label:"Milton Keynes",
  value:"Milton Keynes",
  code:"milton-keynes",
  holes: [{label:"Volcano Valley", value:"Volcano Valley", max:12, id:0, hole_config: mk},{label:"Coney Island Carnival", value:"Coney Island Carnival", max:12, id:1, hole_config: coney},{label:"Tropicana Beach Club", value:"Tropicana Beach Club", max:12, id:2, hole_config: holes2}],
  freeHole:false
}
];



const top =  'exit'
const entryOffset = 100
const exitLength =  0.5
const entryLength = exitLength / 3.5
const entryZ = top === 'entry' ? 1 : 0
const exitZ = top === 'exit' ? 1 : 0

export const swipeTransition = (link, direction) => useTriggerTransition({
  to: link,
  exit: {
    length: exitLength,
    trigger: ({ node, exit }) =>
      swipe({ node, exit, direction: direction, top, entryOffset, triggerName: 'exit' }),
      zIndex: 0
  },
  entry: {
    length: entryLength,
    trigger: ({ node, exit }) =>
      swipe({ node, exit, direction: direction, top, entryOffset, triggerName: 'entry' }),
      zIndex: 1
  }
})


export const getWinningOrder = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
const state = React.useContext(GlobalStateContext);

  if (state){
  var winners = {};
  for (var i = 0; i < state.players.length; i++) {
      winners[state.players[i]] = "";
  }

  if (state != null) {
      if (state.scores !== undefined) {
          var winner = 1;

          var players = [];
          console.log(state.scores);

          for (var j = 0; j < state.players.length; j++) {
              players.push(0);
          }
          for (var j = 0; j < state.scores.length; j++) {
              for (var i = 0; i < state.scores[j].length; i++) {
                  players[i] = players[i] + state.scores[j][i];
              }
          }
      }
  }
  var count = 0;
  for (var i in winners) {
      winners[i] = players[count];
      count++;
  }
  const sortedWinners = Object.fromEntries(
      Object.entries(winners).sort(([,a],[,b]) => a-b)
  );
  console.log(sortedWinners);

  return sortedWinners;
  }
}